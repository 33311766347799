.navbar {
    background: linear-gradient(90deg, var(--primary-dark), var(--primary-color));
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 5% 1rem 5%;
}

.navbar-brand {
    background: -webkit-linear-gradient(left, var(--secondary-color), var(--primary-color));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: default;
    filter: brightness(1.15);
}