.logout-btn {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 20px;
    color: var(--classic-off-white);
    cursor: pointer;
    font-weight: bold;
    padding: 5px 10px;
    box-shadow: -1px 2px 6px 0px var(--box-shadow-color);
}
