.public-nav li {
    list-style: none;
}

.public-nav a {
    text-decoration: none;
}

.home-ul {
    display: flex;
    justify-content: right;
    align-items: center;
}

.home-ul li {
    padding-right: 1rem;
}

.sign-in-link {
    font-weight: 600;
}

.auth-ul {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 1.7rem;
}

.auth-navbar-brand {
    font-size: 2em;
    font-weight: bold;
    background: var(--text-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}