.top-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 5% 1rem 5%;
}

.group-details-back-btn {
    background-color: var(--classic-off-white);
    padding: 0;
}

.delete-group-btn {
    background-color: var(--classic-off-white);
    border: none;
    cursor: pointer;
}

.delete-group-confirmation {
    background-color: var(--classic-off-white);
    font-size: .9rem;
    text-align: center;
}

.delete-group-spinner {
    width: 25px;
    height: 25px;
    margin: 0 auto 0 auto !important;
}

.cancel-delete-group-btn, .confirm-delete-group-btn {
    background-color: var(--classic-off-white);
    cursor: pointer;
    font-weight: 500;
    padding: 0px 8px;
}

.cancel-delete-group-btn {
    color: var(--dark-neutral);
    margin-right: .5rem;
}

.confirm-delete-group-btn {
    color: var(--red-color);
}

.group-name-and-about-text {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    padding: .5rem;
    position: relative;
    color: var(--classic-off-white);
}

.edit-group-btn {
    position: absolute;
    right: 1px;
    top: 1px;
    color: var(--classic-off-white);
    font-size: large;
    z-index: 3;
}

.edit-group-btn:visited {
    color: var(--classic-off-white);
}

.group-name-and-about-text h1, .group-name-and-about-text p {
    position: relative;
    z-index: 2;
}

.group-name-and-about-text::after {
    content: "";
    background: var(--overlay-gradient);
    border-radius: 3px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.group-details-container h2 {
    text-align: center;
    margin-top: 2.1rem;
}

.center-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1rem 0;
}

.add-contacts-to-group {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    color: var(--primary-color);
    cursor: pointer;
    padding-right: 1rem;
}

.gd-email-group {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
}

.add-contacts-to-group svg {
    stroke-width: 1.3125rem;
}

.group-details-modal .modal-content, .email-group-modal .modal-content {
    max-width: 752px;
}

.close-modal-control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.close-modal-btn {
    background-color: var(--classic-off-white);
    border: none;
    cursor: pointer;
    padding: 0;
}

.close-modal-btn svg {
    width: 1.5rem;
    height: 1.5rem;
}

.add-group-contacts-modal-header {
    border-bottom: 1px solid var(--dark-neutral);
    margin-bottom: 1.5rem;
}

.modal-contacts-container {
    border-bottom: 1px solid lightgrey;
    margin-bottom: 1rem;
	padding-bottom: 1rem;
	text-align: center;
}

.modal-contact-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.group-contact-img {
    border-radius: 20px;
    width: 2rem;
    height: 2rem;
    object-fit: cover;
    object-position: center;
}

.contact-added-btn {
    background-color: var(--classic-off-white);
    border-color: var(--secondary-color);
    color: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 3px 23px;
}

.contact-added-btn svg {
    stroke-width: 4px;
}

.modal-add-contact-btn {
    background-color: var(--classic-off-white);
    border-color: var(--primary-color-light);
    padding: 3px 9px;
    cursor: pointer;
}

.modal-add-contact-btn:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--classic-off-white);
    font-weight: bold;
}

.email-group-modal-header {
    border-bottom: 1px solid var(--dark-neutral);
    margin-bottom: 1.5rem;
}

.email-group-subject, .email-group-textarea {
    border: 1px solid;
    border-radius: 3px;
    margin-bottom: 1rem;
    padding: 4px 6px;
}

.email-group-subject:focus-visible, .email-group-textarea:focus-visible {
    border: 2px solid;
    border-color: var(--secondary-color);
    outline: var(--secondary-color);
}

.email-group-textarea {
    width: 100%;
    height: 12rem;
}

.send-group-email-btn {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--classic-off-white);
    font-weight: bold;
}

.send-group-email-btn:hover {
    cursor: pointer;
}

.group-contacts-container {
    width: 90%; 
    margin: 0 auto;
    height: 33rem;
    background-color: var(--light-grey);
    border-radius: 3px;
    overflow-y: scroll;
    scrollbar-width: none;
}

.group-contacts-container::-webkit-scrollbar {
    display: none;
}

.group-contact-container {
    border-bottom: 1px solid;
    border-bottom-color: lightgray;
    width: 100%;
    padding: .5rem 5% 1rem 5%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
}

.group-contact-container h1 {
    font-size: 1.3rem;
}

.group-contact-container p {
    font-size: 0.9em;
}

.group-contact-card-control-left {
    padding-top: 1rem;
    flex-shrink: 20;
}

.group-contact-card-control-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gd-more-options {
    position: relative;
}

.gd-more-options ul {
    position: absolute;
    right: 0;
    width: 8.5394rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 15px;
}

.gd-more-options a {
    width: 100%;
    border-bottom: 1px solid;
}

.gd-more-options a:nth-child(2) {
    padding: 2px 0;
}

.gd-more-options span {
    cursor: pointer;
    text-align: center;
}

.more-options-ul svg {
    width: 100%;
    height: 1.3rem;
}

.delete-contact-from-group {
    width: 100%;
    padding-top: 3px;
    color: var(--red-color);
}

.remove-contact-confirmation {
    font-size: .8rem;
    margin-top: .7rem;
    text-align: center;
}

.remove-contact-from-group-spinner {
    width: 30px;
    margin: 0 auto 0 auto !important;
}

.remove-contact-btns-control {
    padding-top: 5px;
}

.cancel-remove-contact-btn {
    color: var(--dark-neutral);
    margin-right: .5rem;
}

.cancel-remove-contact-btn, .confirm-remove-contact-btn {
    background-color: var(--classic-off-white);
    cursor: pointer;
    font-weight: 500;
    padding: 2px 8px;
}

.confirm-remove-contact-btn {
    color: var(--red-color);
}

.group-contact-card-control-right img {
    width: calc(5.8rem + 1vw);
    height: calc(5.8rem + 1vw);
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}

/***** MEDIA QUERIES *****/

/* MEDIUM */
@media (min-width: 768px) {
    .delete-group-confirmation {
        display: flex;
        align-items: center;
    }
    .delete-group-confirmation span {
        margin-right: .5rem;
    }
    .group-name-and-about-text {
        height: calc(5.5rem + 2vw);
    }
    .group-details-container h2 {
        font-size: calc(24px + .5vw);
    }
    .modal-contacts-container {
        border-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .modal-contact-info {
        justify-content: initial;
        margin-bottom: 0;
    }
    .contact-added-btn {
        margin: 0;
    }
    .group-contacts-container {
        padding-top: 1rem;
    }
    .group-contact-container {
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 1rem;
        text-align: initial;
        background-color: var(--classic-off-white);
        border-radius: 3px;
        border-bottom: 0;
        box-shadow: -1px 2px 6px 0px var(--box-shadow-color);
        margin: .5rem auto 1.5rem auto;
        width: 75%;
        max-width: 810px;
    }
    .group-contact-container h1 {
        font-size: calc(1rem + .5vw);
    }
    .remove-contact-confirmation {
        background-color: var(--classic-off-white);
    }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1024px) and (min-height: 1024px) and (max-height: 1368px) {
    .group-contacts-container {
        height: 70vh;
    }
}

/* LARGE */
@media (min-width: 1200px) {
    .group-contact-container {
        max-width: 864px;
    }
    .group-contact-card-control-left {
        font-size: 1.2rem;
    }
    .contact-card-control-right img {
        max-width: 112px;
    }
    .remove-contact-confirmation {
        font-size: 1rem;
    }
}

/* XX LARGE */
@media (min-width: 1921px) {
    .group-details-container {
        max-width: 1920px;
        margin: 0 auto;
    }
    .group-contacts-container {
        height: 70vh;
    }
}