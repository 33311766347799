.account-side-nav {
    background-color: var(--light-grey);
    border: 1px solid var(--light-neutral);
    border-radius: 0;
    color: var(--dark-neutral);
    font-weight: 600;
    width: 17.5%;
    height: 12rem;
    top: 9.6rem;
    left: 5%;
    list-style: none;
    padding-top: 1.9rem;
    position: absolute;
    box-shadow: none;
}

.account-side-nav a {
    color: var(--dark-neutral);
    font-weight: 600;
    text-decoration: none;
}

.account-side-nav a:visited {
    color: var(--dark-neutral);
}

.account-side-nav .nav-dropdown-logout {
    width: 58%;
}

.account-main-heading {
    width: 90%;
    margin: 1rem auto 0 auto;
}

.user-account-info {
    width: 90%;
    margin: 3rem auto 0 auto;
}

.account-page-logout-btn {
    margin-top: 1rem;
}

/***** MEDIA QUERIES *****/

/* Large */
@media (min-width: 1200px) {
    .account-main-heading {
        width: 45%;
    }
    .user-account-info {
        width: 45%;
    }
}