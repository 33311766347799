.groups-container {
    width: 90%;
    margin: 0 auto;
}

.groups-h1 {
    margin: 1rem 0;
}

.display-groups-container {
    background-color: var(--light-grey);
    border-radius: 3px;
    padding: 1.125rem;
}

.group-card {
    width: auto;
    height: 14.5rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: 1.5rem 1.3rem 2.5rem 1.3rem;
    position: relative;
    text-align: center;
}

.group-card::after {
    content: "";
    background: var(--overlay-gradient);
    border-radius: 3px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.group-details-link {
    text-decoration: none;
    color: var(--classic-off-white);
    cursor: pointer;
}

.group-details-link:visited {
    color: var(--classic-off-white);
}

.group-card h2, .group-card p {
    position: relative;
    z-index: 2;
}

.add-group-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 14.5rem;
}

.add-group-link, .add-group-icon {
    width: 100%;
    height: 100%;
    cursor: default;
}

.add-group-icon {
    color: lightgrey;
    cursor: pointer;
}

.view-groups-side-nav {
    background-color: var(--light-grey);
    border: 1px solid var(--light-neutral);
    border-radius: 0;
    color: var(--dark-neutral);
    font-weight: 600;
    width: 17.5%;
    height: 12rem;
    top: 9.6rem;
    left: 5%;
    list-style: none;
    padding-top: 1.9rem;
    position: absolute;
    box-shadow: none;
}

.view-groups-side-nav a {
    color: var(--dark-neutral);
    font-weight: 600;
    text-decoration: none;
}

.view-groups-side-nav a:visited {
    color: var(--dark-neutral);
}

.view-groups-side-nav .nav-dropdown-logout {
    width: 58%;
}

/***** MEDIA QUERIES *****/

/* SMALL */
@media (max-width: 767px) {
    .groups-container {
        max-width: 576px;
    }
}

/* MEDIUM */
@media (min-width: 768px) {
    .groups-container {
        width: 75%;
        margin: 0 auto;
    }
    .display-groups-container {
        background-color: var(--light-grey);
        border-radius: 3px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
        gap: 24px;
        padding: 1.125rem;
    }
    .group-card {
        margin-bottom: 0;
    }
}

@media (max-width: 1199px) {
    .groups-container {
        max-width: 770px;
    }
}

/* LARGE */
@media (min-width: 1200px) {
    .groups-container {
        width: 45%;
    }
}

@media (min-width: 1921px) {
    .view-groups-container {
        max-width: 1920px;
    }
}