.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}
  
.modal-content {
    background-color: var(--classic-off-white);
    width: 90%;
    height: 50%;
    max-height: 450px;
    padding: 10px;
    border: 1px solid;
    border-color: var(--secondary-color);
    border-radius: 3px;
    overflow-y: scroll;
    scrollbar-width: none;
}

.modal-content::-webkit-scrollbar {
    display: none;
}

/***** MEDIA QUERIES *****/

/* Medium */
@media (min-width: 768px) and (max-height: 700px) {
    .modal-content {
        height: 65%;
    }
}