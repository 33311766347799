.import-contacts-container {
  width: 90%;
  margin: 0 auto;
}

.import-contacts-main-heading {
  margin-top: 1rem;
}

.import-contacts-side-nav {
  background-color: var(--light-grey);
  border: 1px solid var(--light-neutral);
  border-radius: 0;
  color: var(--dark-neutral);
  font-weight: 600;
  width: 17.5%;
  height: 12rem;
  top: 9.6rem;
  left: 5%;
  list-style: none;
  padding-top: 1.9rem;
  position: absolute;
  box-shadow: none;
}

.import-contacts-side-nav a {
  color: var(--dark-neutral);
  font-weight: 600;
  text-decoration: none;
}

.import-contacts-side-nav a:visited {
  color: var(--dark-neutral);
}

.import-contacts-side-nav .nav-dropdown-logout {
  width: 58%;
}

.import-contacts-input {
  cursor: pointer;
  margin-top: 1rem;
}

/***** MEDIA QUERIES *****/

/* Large */
@media (min-width: 1200px) {
  .import-contacts-container {
   width: 45%;
   margin: 0 auto;
  }
}