.button {
    text-decoration: none;
    border: 1px solid;
    border-radius: 20px;
    padding: 6px 12px;
    box-shadow: -1px 2px 6px 0px var(--box-shadow-color);
}

.button > a {
    text-decoration: none;
}