.create-contact-heading {
    width: 90%;
    margin: 1rem auto 1rem auto;
}

.create-contact-form {
    width: 90%;
    margin: 0 auto;
}

.create-contact-form label {
    font-weight: 600;
}

.create-contact-form input {
    background-color: var(--light-grey);
    border: 0;
    border-bottom: 1px solid;
    border-bottom-color: lightgrey;
    border-radius: 5px;
}

.first-and-last-name-container {
    margin-bottom: 1.5rem;
    max-width: 500px;
}

.first-name-control {
    display: flex;
    flex-direction: column;
    max-width: 170px;
}

.last-name-control {
    display: flex;
    flex-direction: column;
    max-width: 170px;
}

.email-and-phone-container {
    margin-bottom: 1.5rem;
    max-width: 170px;
}

.phone-control {
    display: flex;
    flex-direction: column;
    max-width: 345.6px;
}

.email-control {
    display: flex;
    flex-direction: column;
    max-width: 345.6px;
}

.address1-control {
    display: flex;
    flex-direction: column;
    max-width: 345.6px;
}

.address2-control {
    display: flex;
    flex-direction: column;
    max-width: 170px;
}

.city-state-zip-container {
    margin-bottom: 1.5rem;
}

.city-control {
    display: flex;
    flex-direction: column;
    max-width: 170px;
}

.state-control {
    display: flex;
    flex-direction: column;
    max-width: 80px;
}

.zip-control {
    display: flex;
    flex-direction: column;
    max-width: 100px;
}

.categories-and-notes-container {
    margin-bottom: 1.5rem;
}

.notes-control {
    display: flex;
    flex-direction: column;
    max-width: 345.6px;
}

.photo-control {
    margin-bottom: 2rem;
}

.photo-control label {
    display: block;
}

.select-photo {
    background-color: var(--classic-off-white) !important;
    border-bottom: 0 !important;
    width: 75%;
}

.create-contact-submit {
    background-color: var(--primary-color) !important;
    border: 1px solid !important;
    border-color: var(--primary-color) !important;
    border-radius: 20px !important;
    box-shadow: -1px 2px 6px 0px var(--box-shadow-color);
    color: var(--classic-off-white);
    cursor: pointer;
    display: block;
    font-weight: bold;
    margin-bottom: 0.75rem;
    padding: 6px 12px;
    text-decoration: none;
}

.cancel-button {
    background-color: var(--classic-off-white);
    border: 1px solid var(--red-color);
    color: var(--red-color);
    cursor: pointer;
    font-weight: 500;
}

/***** MEDIA QUERIES *****/

/* MEDIUM */
@media (min-width: 768px) {
    .first-and-last-name-container {
        display: flex;
        justify-content: flex-start;
    }
    .first-name-control {
        margin-right: 2rem;
        max-width: 345.6px;
    }
    .last-name-control {
        max-width: 345.6px;
    }
    .email-and-phone-container {
        max-width: 282px;
    }
    .city-state-zip-container {
        display: flex;
        justify-content: flex-start;
        max-width: 500px;
    }
    .address1-control {
        max-width: 414px;
    }
    .city-control {
        margin-right: 2rem;
    }
    .state-control {
        margin-right: 2rem;
    }
    .notes-control {
        max-width: 414px;
    }
    .submit-and-cancel-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .create-contact-submit {
        margin-right: 2rem;
        margin-bottom: 0;
    }
}