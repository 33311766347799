.demo-logout-message {
    margin-top: 1rem;
    text-align: center;
}

.side-nav a, .side-nav li {
    display: flex;
    align-items: center;
}

.side-nav svg {
    color: var(--primary-color);
    margin-right: .5rem;
    width: 1.1rem;
    height: 1.1rem;
}

.side-nav li:nth-child(2) svg {
    width: 1.3rem;
    height: 1.3rem;
    margin-right: .36rem;
    margin-left: -1px;
}