.searchbar {
    width: 100%;
    border: 1px;
    border-radius: 5px;
    background-color: var(--light-grey);
    margin: 0 5%;
}

.searchbar:focus {
    background-color: var(--classic-off-white);
    outline-color: var(--primary-dark);
    outline-style: solid;
    outline-width: 2px;
}