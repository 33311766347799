.toast-alert {
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-weight: 500;
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 10px;
}
  
.toast-alert.success {
    background-color: var(--success-color);
    color: var(--success-text);
}
  
.toast-alert.error {
    background-color: var(--error-color);
    color: var(--error-text);
}

.toast-alert.info {
    background-color: var(--info-color);
    color: var(--info-text);
}

.close-toast-icon {
    cursor: pointer;
    font-size: 18px;
    margin-left: 10px;
    stroke-width: .5px;
}