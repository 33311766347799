.si-page-container {
    width: 100vw;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-in-card {
    margin-top: 2rem;
    max-width: 26rem;
}

.sign-in-h1 {
    background: var(--text-gradient);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sign-in-form {
    display: flex;
    flex-direction: column;
    margin-top: .5rem;
}

.sign-in-form input {
    margin-top: 1rem;
    border: 1px solid;
    border-radius: 20px;
    padding: 6px 12px;
}

.sign-in-form input:focus {
    background-color: var(--classic-off-white);
    outline-color: var(--secondary-color);
}

.sign-in-buttons-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.75rem;
}

.sign-in-button {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--classic-off-white);
    cursor: pointer;
    font-weight: 600;
    margin-right: .5rem;
}

.google-sign-in-button {
    text-decoration: none;
    background-color: var(--classic-off-white);
    border: 1px solid;
    border-color: white;
    border-radius: 20px;
    box-shadow: -1px 2px 6px 0px var(--box-shadow-color);
    color: black;
    cursor: pointer;
    padding: 6px 12px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.google-sign-in-button:hover {
    background-color: var(--google-blue);
    border-color: var(--google-blue);
    color: white;
}

.google-sign-in-button:active {
    border-color: var(--google-blue);
    box-shadow: 0 0 6px 0 var(--google-alt-blue);
    color: white;
}

.google-sign-in-button > svg {
    display: inline-block;
    margin-right: 1rem;
}

.sign-in-form-p {
    margin-top: .6rem;
}

.sign-up-link {
    color: var(--primary-color);
}

/***** MEDIA QUERIES *****/

@media (max-width: 393px) and (max-height: 852px) {
    .si-page-container {
        height: 80vh;
    }
    .sign-in-buttons-container {
        display: block;
        font-size: .9rem;
        margin-top: 1rem;
    }
    .sign-in-button {
        margin-bottom: .5rem;
        padding: 2px 8px;
    }
    .google-sign-in-button {
        padding: 3px 10px;
    }
}