.su-page-container {
    width: 100vw;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-up-card {
    margin-top: 2rem;
    max-width: 26rem;
}

.sign-up-h1 {
    background: -webkit-linear-gradient(left, var(--secondary-color), var(--primary-color));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sign-up-form {
    display: flex;
    flex-direction: column;
    margin-top: .5rem;
}

.sign-up-form input {
    margin-top: 1rem;
    border: 1px solid;
    border-radius: 20px;
    padding: 6px 12px;
}

.sign-up-form input:focus {
    background-color: var(--classic-off-white);
    outline-color: var(--secondary-color);
}

.sign-up-button {
    margin-top: 1.75rem;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--classic-off-white);
    cursor: pointer;
    font-weight: 600;
    width: fit-content;
}

.sign-up-form-p {
    margin-top: .6rem;
}

.sign-in-link {
    color: var(--primary-color);
}