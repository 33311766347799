.top-controls-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.main-heading-flex-container {
    width: 90%;
    margin: 1rem auto 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.create-contact-icon {
    width: 2rem;
    height: 2rem;
}

.create-contact-link {
    color: black;
}

.view-contacts-search {
    width: 90%;
    margin: 0 5% 1rem 5%;
    padding: 3px 2px 3px 5px;
}

.search-results-container {
    width: 90%;
    height: auto;
    margin: 0 5%;
    background-color: var(--classic-off-white);
    border-radius: 5px;
    box-shadow: -1px 2px 6px 0px var(--box-shadow-color);
    padding-left: 5px;
    position: absolute;
    top: 10.5rem;
    z-index: 5;
}

.search-results-container li {
    list-style: none;
}

.search-results-container a {
    color: black;
    text-decoration: none;
}

.search-results-container a:hover {
    color: var(--primary-color);
}

.view-contacts-side-nav {
    background-color: var(--light-grey);
    border: 1px solid var(--light-neutral);
    border-radius: 0;
    color: var(--dark-neutral);
    font-weight: 600;
    width: 17.5%;
    height: 12rem;
    top: 12.9rem;
    left: 5%;
    list-style: none;
    padding-top: 1.9rem;
    position: absolute;
    box-shadow: none;
}

.view-contacts-side-nav a {
    color: var(--dark-neutral);
    font-weight: 600;
    text-decoration: none;
}

.view-contacts-side-nav a:visited {
    color: var(--dark-neutral);
}

.view-contacts-side-nav .nav-dropdown-logout {
    width: 58%;
}

.contact-container {
    border-bottom: 1px solid lightgrey;
    width: 100%;
    padding: 0 5%;
    padding-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.disabled-appearance {
    color: lightgrey;
}

.contact-container h1 {
    font-size: calc(0.917rem + 1vw);
}

.contact-card-control-left {
    padding-top: 1rem;
}

.contact-card-control-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.delete-contact-confirmation {
    background-color: var(--classic-off-white);
    font-size: .8rem;
    margin-top: .7rem;
    text-align: center;
}

.delete-contact-btns-control {
    padding-top: 5px;
}

.cancel-delete-contact-btn {
    color: var(--dark-neutral);
    margin-right: .5rem;
}

.cancel-delete-contact-btn, .confirm-delete-contact-btn {
    background-color: var(--classic-off-white);
    cursor: pointer;
    font-weight: 500;
    padding: 2px 8px;
}

.confirm-delete-contact-btn {
    color: var(--red-color);
}

.vc-more-options {
    position: relative;
}

.vc-more-options ul {
    position: absolute;
    right: 0;
    width: 8.2306rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 15px;
}

.vc-more-options a {
    width: 100%;
    border-bottom: 1px solid;
}

.vc-email-contact, .edit-contact-link {
    padding: 2px 0;
}

.vc-email-contact {
    width: 100%;
    border-bottom: 1px solid;
    cursor: pointer;
}

.more-options-ul svg {
    width: 100%;
    height: 1.3rem;
}

.vc-delete-contact {
    width: 100%;
    padding-top: 3px;
    color: var(--red-color);
    cursor: pointer;
}

.contact-card-control-right img {
    width: calc(4.02rem + 1vw);
    height: calc(4.02rem + 1vw);
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
}

.email-contact-modal .modal-content {
    max-width: 752px;
}

.email-contact-modal-header {
    border-bottom: 1px solid lightgrey;
    margin-bottom: 1.5rem;
}

.email-contact-subject, .email-contact-textarea {
    border: 1px solid;
    border-radius: 3px;
    margin-bottom: 1rem;
    padding: 4px 6px;
}

.email-contact-subject:focus, .email-contact-textarea:focus {
    outline-color: var(--secondary-color);
}

.email-contact-textarea {
    width: 100%;
    height: 12rem;
}

.send-contact-email-btn {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--classic-off-white);
    cursor: pointer;
    font-weight: bold;
}

/***** MEDIA QUERIES *****/

/* XX SMALL */
@media (max-width: 242px) {
    .contact-card-control-left {
        max-width: 75%;
    }
}

/* X SMALL */
@media (max-width: 379px) {
    .contact-container {
        flex-direction: column-reverse;
        text-align: center;
    }
    .contact-card-control-left {
        font-size: .89rem;
    }
}

/** MEDIUM **/
@media (min-width: 768px) {
    .main-heading-flex-container {
        max-width: 846px;
    }
    .view-contacts-search {
        max-width: 846px;
    }
    .search-results-container {
        max-width: 846px;
    }
    .contacts-container {
        padding-bottom: .1rem;
    }
    .contact-container {
        border-radius: 3px;
        border-bottom: 0;
        box-shadow: -1px 2px 6px 0px #bfbfbfdb;
        margin: .5rem auto 1.5rem auto;
        width: 90%;
        max-width: 846px;
        height: 8.5rem;
    }
    .contact-card-control-left {
        font-size: 1.1rem;
    }
    .contact-container h1 {
        font-size: calc(1rem + 1vw);
    }
    .more-options-icon {
        width: 1.75rem;
        height: 1.75rem;
    }
    .contact-card-control-right img {
        height: 6rem;
        width: 6rem;
    }

    /* TODO: move more options icon location? */
    /* TODO: build different pop up component for hamburger menu? */
}

/* Edge Case */
@media (min-width: 1200px) and (max-width: 1311px) {
    .contact-card-control-right {
        width: 25%;
    } && .delete-contact-confirmation {
        width: 204%;
    }
}

/** LARGE **/
@media (min-width: 1200px) {
    .main-heading-flex-container {
        width: 45%;
    }
    .view-contacts-search {
        width: 45%;
        margin-bottom: 2rem;
    }
    .search-results-container{
        width: 45%;
    }
    .contacts-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contact-container {
        width: 45%;
        height: 9rem;
    }
    .contact-card-control-left {
        font-size: 1.2rem;
        width: 75%;
    }
    .contact-container h1 {
        font-size: 1.75rem;
    }
    .more-options-icon {
        width: 2rem;
        height: 2rem;
    }
    .contact-card-control-right img {
        height: 7rem;
        width: 7rem;
        max-width: 112px;
    }
}

/* XX LARGE */
@media (min-width: 1921px) {
    .contacts-container {
        max-width: 1920px;
        margin: 0 auto;
    }
}