.edit-group-title {
    margin: 1rem 0 1rem 5%;
}

.edit-group-form {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.edit-group-form label {
    font-weight: 600;
    max-width: 345.6px;
}

.edit-group-form input {
    background-color: var(--light-grey);
    border: 0;
    border-bottom: 1px solid;
    border-bottom-color: lightgray;
    border-radius: 5px;
    margin-bottom: .75rem;
    max-width: 345.6px;
}

.edit-cover-picture {
    background-color: var(--classic-off-white) !important;
    border-bottom: 0 !important;
    border-radius: 3px !important;
}

.group-update-and-cancel-btns {
    margin-top: 1rem;
}

.update-group-btn {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--classic-off-white);
    cursor: pointer;
    display: block;
    font-weight: bold;
    margin-bottom: 0.75rem;
}

.edit-group-cancel-btn {
    background-color: var(--classic-off-white);
    border: 1px solid var(--red-color);
    border-radius: 20px;
    box-shadow: -1px 2px 6px 0px var(--box-shadow-color);
    color: var(--red-color);
    cursor: pointer;
    font-weight: 500;
    padding: 6px 12px;
}

/***** MEDIA QUERIES *****/

/* MEDIUM */
@media (min-width: 768px) {
    .edit-group-form label {
        width: 50%;
        max-width: 400px;
    }
    .edit-group-form input {
        width: 50%;
        max-width: 400px;
    }
    .update-group-btn {
        display: inline-block;
        margin-right: 2rem;
        margin-bottom: 0;
    }
}