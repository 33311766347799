.create-group-container {
    width: 90%;
    margin: 1rem auto 0 auto;
}

.create-group-container h1 {
    margin-bottom: 1rem;
}

.create-group-form label {
    display: block;
    font-weight: 600;
    width: fit-content;
}

.create-group-form input {
    background-color: var(--light-grey);
    border: 0;
    border-bottom: 1px solid lightgrey;
    border-radius: 5px;
}

.group-name-input, .about-group-input {
    margin-bottom: 1rem;
}

.select-cover-picture {
    background-color: var(--classic-off-white) !important;
    border-bottom: 0 !important;
    margin-bottom: 2rem;
    display: block;
}

.create-group-submit {
    text-decoration: none;
    background-color: var(--primary-color) !important;
    border: 1px solid !important;
    border-color: var(--primary-color) !important;
    border-radius: 20px !important;
    box-shadow: -1px 2px 6px 0px var(--box-shadow-color);
    color: var(--classic-off-white);
    cursor: pointer;
    display: block;
    font-weight: bold;
    margin-bottom: .75rem;
    padding: 6px 12px;
}

.cancel-create-group-btn {
    background-color: var(--classic-off-white);
    border: 1px solid var(--red-color);
    color: var(--red-color);
    cursor: pointer;
    font-weight: 500;
}

/* MEDIA QUERIES */

/* Medium */
@media (min-width: 768px) {
    .create-group-submit {
        display: inline-block;
        margin-right: 2rem;
        margin-bottom: 0;
    }
}