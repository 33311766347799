.contact-details-container {
    width: 65%;
    height: 100dvh;
    margin: 0 auto;
}

.go-back-link {
    margin-top: 1rem;
    background-color: var(--classic-off-white);
    padding-left: 0;
}

.contact-details-card {
    margin: 1rem auto 0 auto;
    width: auto;
}

.contact-details-image {
    border-radius: 100%;
    width: 10rem;
    height: 10rem;
    margin: 0 auto;
    object-fit: cover;
    object-position: center;
}

.contact-details-name {
    text-align: center;
    margin: 1rem 0 .5rem 0;
}

.contact-details-phone, .contact-details-email, .contact-details-address, .contact-details-group {
    /* remove border & padding at medium media query if I don't want horizontal lines on desktop */
    border-bottom: 1px solid lightgrey;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.contact-details-phone, .contact-details-email, .contact-details-group {
    text-align: center;
}

.contact-details-address {
    text-align: center;
}

.contact-details-address svg {
    width: 1.1875rem;
    height: 1.1875rem;
}

p svg {
    color: var(--primary-color);
    margin: 0 auto;
    width: 1.2rem;
    height: 1.2rem;
}

.contact-details-notes {
    text-align: center;
}

.notes-field {
    text-decoration: underline;
}

/***** MEDIA QUERIES *****/

/* MEDIUM */
@media (min-width: 768px) {
    .contact-details-container {
        max-width: 700px;
    }
    .go-back-link {
        padding-left: 0;
    }
    .contact-details-phone, .contact-details-email, .contact-details-group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .contact-details-address {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    p svg {
        margin: 0 .5rem 0 0;
    }
    .contact-details-notes {
        text-align: left;
    }
}