.mobile-nav-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 2;
}

.mobile-nav-container .hidden {
    display: none;
}

.mobile-nav-menu-icon {
    width: 2em;
    height: 2em;
}

.mobile-nav-container .dropdown {
    z-index: 5;
}

.mobile-nav-ul {
    top: 3.2rem;
    left: calc(54% + 1vw);
    right: calc(6% - 0.5vw);
}

.mobile-nav-ul li {
    list-style: none;
}

.mobile-nav-ul a {
    text-decoration: none;
    cursor: pointer;
}

.nav-dropdown-logout {
    cursor: pointer;
    width: 50%;
}

/***** MEDIA QUERIES *****/

/* XX SMALL */
@media (max-width: 279px) {
    .mobile-nav-ul {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 280px) {
    .mobile-nav-ul {
        left: auto;
        width: 40.5%;
        max-width: 280px;
    }
}
