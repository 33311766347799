/***** Global Variables *****/

:root {
  /* colors */
  --primary-color: #6E6BEE;
  --primary-dark: #5957D1;
  --secondary-color: #FFDAB9;
  --alt-secondary-color: #ffbd59;
  --classic-off-white: #FDFDFD;
  --dark-neutral: #333333;
  --light-neutral: #E0E0E0;
  --success-color: #8EB89F;
  --success-text: #2F4A40;
  --error-color: #D84A5F;
  --error-text: #5A1E22;
  --alt-error-color: #FF6B6B;
  --info-color: #6FAED9;
  --info-text: #1F3A56;
  --red-color: #C82333;
  --box-shadow-color: #bfbfbfdb;
  --google-blue: #4285F4;
  --google-alt-blue: #1669F2;
  --light-grey: #f5f5f5;
  --text-gradient: -webkit-linear-gradient(left, var(--secondary-color), var(--primary-color));
  --overlay-gradient: linear-gradient(rgb(117 116 116 / 40%), rgba(60,60,60,0.7));

  /* bp sizes */
  --max-w-x-sm: 575px;
  --min-w-sm: 576px;
  --max-w-sm: 767px;
  --min-w-md: 768px;
  --max-w-md: 991px;
  --min-w-lg: 992px;
  --max-w-lg: 1199px;
  --min-w-x-lg: 1200px;
  --max-w-x-lg: 1399px;
  --min-w-xx-lg: 1400px;
}

/***** CSS Reset *****/

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  background-color: var(--classic-off-white);
  font-family: 'Be Vietnam Pro', sans-serif;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img, picture, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

a:visited {
  text-decoration: none;
  color: black;
}